<template>
  <base-button ref="button"
               size="small"
               :outlined="true"
               :loading="loading"
               @click="send">Send Online Request</base-button>
</template>

<script>
import webhookApi from '@/api/monitorNotifications/webhookApi.js'
import tippy from 'tippy.js'

export default {
  props: {
    monitor: {
      required: true,
      type: Object
    }
  },

  data () {
    return {
      tippyInstance: null,
      loading: false
    }
  },

  mounted () {
    this.initTippy()
  },

  methods: {
    async send () {
      this.loading = true
      const success = await webhookApi.sendTestOnlineRequest(this.monitor.id)
      this.loading = false

      if (success) {
        this.triggerTippy('Request was successfully sent')
      } else {
        this.triggerTippy('Something went wrong, check your URL')
      }
    },

    initTippy () {
      this.tippyInstance = tippy(this.$refs.button.$el, {
        content: 'Request sent successfully',
        arrow: false,
        placement: 'bottom',
        trigger: 'manual'
      })
    },

    // TODO: A bit of copy-paste, can be refactored
    triggerTippy (content) {
      this.tippyInstance.setProps({
        content
      })

      this.tippyInstance.show()

      setTimeout(() => {
        this.tippyInstance.hide()
      }, 2000)
    }
  }
}
</script>
